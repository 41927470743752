import { ReducerAction } from '@get-busy/common';
import { put, takeEvery } from 'redux-saga/effects';
import { openToastRoutine } from '../routines/toast.routines';

function* openToast(action: ReducerAction<any>) {
  try {
    yield put(openToastRoutine.success(action.payload));
  } catch (e) {
    yield put(openToastRoutine.failure(e));
  }
}

export function* toastSagas() {
  yield takeEvery(openToastRoutine.TRIGGER, openToast);
}
