import { WithTheme } from '@get-busy/common';
import { withTheme } from '@get-busy/components';
import { Typography } from '@mui/material';
import React, { PureComponent } from 'react';

export class Component extends PureComponent<WithTheme> {
  render() {
    const { theme } = this.props;
    return (
      <Typography
        className="w-100 px-2 py-0 display-block"
        variant="caption"
        align="center"
        color="white"
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        Copyright 2023 Elevate Software and Services, Inc.
      </Typography>
    );
  }
}

export const FooterComponent = withTheme(Component);
