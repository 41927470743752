/* eslint-disable default-param-last */
import { ReducerAction, Toast } from '@get-busy/common';
import { cloneDeep, merge } from 'lodash';
import { openToastRoutine } from '../routines/toast.routines';

const initialState: Toast = {
  model: {
    autoHideDuration: 800,
    content: '',
    open: false,
    severity: 'info',
    variant: 'standard',
  },
};

export const toast = (state = initialState, action: ReducerAction<any>): Toast => {
  const { type, payload } = action;
  let newState = cloneDeep(state);
  switch (type) {
    case openToastRoutine.SUCCESS: {
      newState = merge(newState, {
        model: {
          ...payload,
        },
      });
      break;
    }
    default: {
      return newState;
    }
  }
  return newState;
};
