import { MediaQueryProps } from '@get-busy/common';
import { withMediaQueries } from '@get-busy/components';
import { Grid, Typography } from '@mui/material';
import React, { PureComponent } from 'react';
import { revenueQuestions } from './constants/questions.constants';

const revenueGenearation = require('../../../../static/images/revenu-genration.png');

export class Component extends PureComponent<MediaQueryProps> {
  render() {
    const { isExtraSmall, isMedium, isLarge, isExtraLarge } = this.props;
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="pt-6 mt-3 common__container"
      >
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <img
            src={revenueGenearation}
            alt="Revenue Genearation"
            className="revenue__image"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <Typography
            align="left"
            variant={`${(isMedium || isLarge || isExtraLarge) ? 'h4' : 'h5'}`}
            className="fw-500 w-100"
          >
            Do you know how much will you make this year?
          </Typography>
          <ul
            className={`${isExtraSmall && 'pl-4'}`}
          >
            {revenueQuestions.map((questions) => (
              <li className="pb-2">
                <Typography
                  align="left"
                  variant={`${(isExtraLarge) ? 'h6' : 'subtitle1'}`}
                  className="fw-400"
                >
                  {questions}
                </Typography>
              </li>
            )) }
          </ul>
          <Typography
            align="justify"
            variant={`${(isExtraLarge) ? 'h6' : 'subtitle1'}`}
            className="fw-400"
          >
            As an independent service provider, you have the flexibility to choose your
            clients. Rather than providing services to anyone who needs them,
            prioritizing high-potential clients with a history of recurring work
            can lead to a more predictable and profitable income. By identifying and
            focusing on these clients, you can earn a lot more with fewer clients,
            ensuring a predictable schedule and consistent earnings.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export const RevenueComponent = withMediaQueries(Component);
