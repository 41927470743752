import {
  cancel_line,
  close,
  siteLogo_blue_sm,
  verified,
} from "../../../../static/logos/icon-components";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import cleanEqupment from "../../../../assets/images/clean-equpment.png";
import {
  invalid_type_error,
  required_error,
  short_value_message,
  validation_error_message,
} from "@get-busy/common";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { showErrorToast } from "./generic-campaign.component";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  p: 3,
};

type FormData = {
  email: string;
  name: string;
  phoneNumber: string;
  services: string;
  city: string;
  [key: string]: string; // Index signature
};

export const HomeCleaningCampaign = () => {
  const [businessType, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [email, setEmail] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [formData, setFormData] = useState<FormData>({
    email: "",
    name: "",
    phoneNumber: "",
    services: "",
    city: "",
  });

  const [validationErrors, setValidationErrors] = useState<any>({
    email: null,
    name: null,
    phoneNumber: null,
    services: null,
    city: null,
  });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleValidation = (inputField: any, value: any) => {
    try {
      if (value.trim() === "") {
        throw new Error(required_error);
      }
      validationSchemas[inputField].parse(value);
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [inputField]: null,
      }));
    } catch (error: any) {
      const errorMessage =
        error.errors?.[0]?.message || error.message || `Invalid ${inputField}`;
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [inputField]: errorMessage,
      }));
    }
  };

  const emaiIsUser = async (email: any) => {
    try {
      const response = await axios.post(
        "https://www.getbusy.info/getbusy/v1/trial-user",
        { email: email, campaignType: "home" }
      );
      return response;
    } catch (err) {
      console.error("err", err);
    }
  };

  const signUpDetail = async (formData: any) => {
    try {
      formData.id = currentId;
      const response = await axios.post(
        "https://www.getbusy.info/getbusy/v1/trial-user/existing",
        { ...formData, campaignType: "home" }
      );
      return response;
    } catch (err) {
      console.error("err", err);
    }
  };

  const emailUserMutation = useMutation({
    mutationKey: ["Email_DETAIL"],
    mutationFn: emaiIsUser,
    onSuccess: (data) => {
      setCurrentId(data?.data.id);
      document.getElementById("home")?.scrollIntoView({
        behavior: "smooth",
      });
      setFormData({
        email: data?.data?.email,
        name: "",
        phoneNumber: "",
        services: "",
        city: "",
      });
    },
    onError(error) {
      showErrorToast(`${error.message}`);
    },
  });

  const signupForm = useMutation({
    mutationKey: ["SIGNUP_DETAIL"],
    mutationFn: signUpDetail,
    onSuccess: () => {
      handleOpen();
    },
    onError(error) {
      showErrorToast(`${error.message}`);
    },
  });

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const userEmailHandler = (event: any) => {
    event?.preventDefault();
    if (!email) {
      setEmailErr("This field cannot be blank");
    } else if (!validateEmail(email)) {
      setEmailErr("Invalid email format");
    } else {
      window.fbq('track', 'Lead');
      emailUserMutation.mutate(email);
    }
  };

  function handleEmailChange(event: any) {
    setEmail(event.target.value);
    if (event.target.value === "") {
      setEmailErr("This field cannot be blank");
    } else if (!validateEmail(event.target.value)) {
      setEmailErr("Invalid email format");
    } else {
      setEmailErr("");
    }
  }

  const validationSchemas: any = {
    name: z
      .string()
      .refine((value) => value.trim() !== "", { message: required_error })
      .refine((value) => value.length > 0 && value.length >= 1, {
        message: short_value_message,
      }),
    phoneNumber: z
      .string()
      .refine((value) => /^\d{0,10}$/.test(value), {
        message: validation_error_message.phoneNumber,
      })
      .refine((value) => value.trim() !== "", { message: required_error }),
    services: z
      .string()
      .refine((value) => value.trim() !== "", { message: required_error })
      .refine((value) => value.length > 0 && value.length >= 1, {
        message: short_value_message,
      }),
    city: z
      .string()
      .refine((value) => value.trim() !== "", { message: required_error })
      .refine((value) => value.length > 0 && value.length >= 1, {
        message: short_value_message,
      }),
    email: z
      .string()
      .refine((value) => value.trim() !== "", { message: required_error })
      .refine((value) => value.length > 0, { message: invalid_type_error })
      .refine((value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
        message: validation_error_message.email.type,
      })
      .refine((value) => value.length > 0, {
        message: validation_error_message.email.value,
      }),
  };

  const handleSignupForm = async (event: any) => {
    event?.preventDefault();
    Object.keys(formData).forEach((field) => {
      handleValidation(field, formData[field]);
    });

    if (Object.values(formData).some((error) => error === "")) {
      return;
    } else if (
      Object.values(validationErrors).some((error) => error !== null)
    ) {
      return;
    }
    try {
      await signupForm.mutate(formData);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <div className="main-wrapper min-h-screen flex flex-col">
      <section className=" capmp-main">
        <div className="max-w-full px-6 container">
          <Box className="flex justify-center site-logo">
            <div className="w-36 h-9 site-logo__wrap">{siteLogo_blue_sm}</div>
          </Box>

          <Box className="mt-11">
            <Box className="capmp-main__innerwrap">
              <div className="capmp-main__title">
                <h1 className="leading-[128%] text-[32px] text-center font-black text-primary-95">
                  Do more jobs, <br /> make more money!
                </h1>
              </div>

              <div className="mt-11 flex flex-wrap capmp-main__img-sec">
                <Box className="mb-6 w-full">
                  <div className="flex justify-center img-box w-full">
                    <img
                      className="object-contain object-center"
                      src={cleanEqupment}
                      alt="clean-equpment"
                    />
                  </div>
                </Box>
              </div>

              <div className="mt-3.5 capmp-main__desc">
                <p className="text-lg text-center text-black">
                  Offer your clients an easy way to <br /> schedule
                  appointments, for free.
                </p>
                <div className="mt-2 flex items-center justify-center text-[10px] space-x-0.5 > * + *">
                  {cancel_line}
                  <p>No credit card required</p>
                </div>
              </div>

              <div className="login-form mt-4">
                <form onSubmit={userEmailHandler} className="add-service-form">
                  <div className="flex field-wrap space-x-2">
                    <div className="flex-1 field">
                      <TextField
                        placeholder="Email"
                        label="Email"
                        variant="outlined"
                        name="Email Name"
                        onChange={handleEmailChange}
                        fullWidth
                        type="text"
                        size="small"
                        focused
                      />
                      <FormHelperText error={Boolean(emailErr)}>
                        {emailErr}
                      </FormHelperText>
                    </div>

                    <div className="field">
                      <Button
                        className="bg-primary-60 font-medium text-sm  py-2 px-2.5 w-full"
                        variant="contained"
                        type="submit"
                        color="primary"
                      >
                        Join waitlist
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </Box>

            <hr className="border-primarry my-20 border-gray" />

            <Box>
              <div className="capmp-singupform">
                <div className="container max-w-full">
                  <div className="space-y-1.5 > * + * form-header">
                    <h3 className="title text-87 text-2xl text-center ">
                      Sign Up
                    </h3>
                    <p className="title text-87 text-sm text-center">
                      Please Provide more contact details and <br /> Get started
                      for free
                    </p>
                  </div>

                  <div className="my-6 p-6 border border-neutral-20 rounded-lg">
                    <form
                      onSubmit={handleSignupForm}
                      id="home"
                      className="add-service-form"
                    >
                      <div className="field-wrap space-y-4">
                        {/* <div className="field">
                          <TextField
                            placeholder="Email"
                            label="Email"
                            variant="outlined"
                            name="email"
                            fullWidth
                            type="text"
                            size="small"
                            value={formData.email}
                            onChange={handleFormChange}
                          />
                          <FormHelperText
                            error={Boolean(validationErrors.email)}
                          >
                            {validationErrors.email}
                          </FormHelperText>
                        </div> */}

                        <div className="field">
                          <TextField
                            placeholder="Full name"
                            label="Full name"
                            variant="outlined"
                            name="name"
                            fullWidth
                            type="text"
                            size="small"
                            value={formData.name}
                            onChange={handleFormChange}
                          />
                          <FormHelperText
                            error={Boolean(validationErrors.name)}
                          >
                            {validationErrors.name}
                          </FormHelperText>
                        </div>

                        <div className="field">
                          <TextField
                            label="Phone number"
                            placeholder="Phone number"
                            fullWidth
                            type="number"
                            name="phoneNumber"
                            size="small"
                            value={formData.phoneNumber}
                            onChange={handleFormChange}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                          <FormHelperText
                            error={Boolean(validationErrors.phoneNumber)}
                          >
                            {validationErrors.phoneNumber}
                          </FormHelperText>
                        </div>

                        {/* <div className="field">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Business Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={businessType}
                              label="businessType"
                              onChange={handleChange}
                            >
                              <MenuItem value={10}>BusinessType 1</MenuItem>
                              <MenuItem value={20}>BusinessType 2</MenuItem>
                              <MenuItem value={30}>BusinessType 3</MenuItem>
                            </Select>
                          </FormControl>
                        </div> */}

                        <div className="field">
                          <TextField
                            placeholder="Services offered"
                            label="Services"
                            variant="outlined"
                            name="services"
                            fullWidth
                            type="text"
                            size="small"
                            value={formData.services}
                            onChange={handleFormChange}
                          />
                          <FormHelperText
                            error={Boolean(validationErrors.services)}
                          >
                            {validationErrors.services}
                          </FormHelperText>
                        </div>

                        <div className="field">
                          <TextField
                            placeholder="City"
                            label="City"
                            variant="outlined"
                            name="city"
                            fullWidth
                            type="text"
                            size="small"
                            value={formData.city}
                            onChange={handleFormChange}
                          />
                          <FormHelperText
                            error={Boolean(validationErrors.city)}
                          >
                            {validationErrors.city}
                          </FormHelperText>
                        </div>

                        <div className="field">
                          <Button
                            // onClick={handleOpen}
                            className="bg-primary-60 font-medium text-sm  py-2.5 px-2 w-full"
                            variant="contained"
                            type="submit"
                            color="primary"
                          >
                            Join Waitlist
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </section>

      <Box className="mt-auto bottom-footer">
        <p className="bg-primary-60 p-3.5 text-center text-xs text-white">
          Copyright 2023 Getbusy. All Rights Reserved
        </p>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-white p-6 modal"
      >
        <Box sx={style} className="rounded">
          <div className="verified-icon flex justify-center">
            <div className="w-[55px] ">{verified}</div>
          </div>

          <div className="modal__title-desc  space-y-2.5 > * + * mt-6">
            <h2 className="text-center text-neutral font-bold text-xl">
              Thank you for Details! <br /> You're now on our waiting list.
            </h2>
            <p className="text-center text-neutral text-sm">
              Your request is in the queue, and our team is diligently working
              on it. We appreciate your understanding.
            </p>
          </div>

          <div className="modal__close mt-10">
            <Link
              className="flex justify-center items-center text-primary-90 hover:text-primary-60 text-sm font-medium no-underline  cursor-pointer space-x-2 > * + *"
              onClick={handleClose}
            >
              <div className="icon-sm">{close}</div>
              <span>Close</span>
            </Link>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
