import { CommonComtainerState, StoreState, ToastStoreState } from '@get-busy/common';
import { Alert, Snackbar } from '@mui/material';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { openToastRoutine } from '../../store/routines/toast.routines';

type ToastComponentProps = CommonComtainerState & ToastStoreState;

class Component extends PureComponent<ToastComponentProps> {
  handleClose = () => {
    const { dispatch } = this.props;
    dispatch(openToastRoutine.trigger({
      open: false,
    }));
  };

  render() {
    const {
      toast: {
        model: {
          content, open = false,
          autoHideDuration = 6000,
          severity,
          variant = 'standard',
        },
      },
    } = this.props;
    return (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
      >
        <Alert
          onClose={this.handleClose}
          severity={severity}
          variant={variant}
        >
          {content}
        </Alert>
      </Snackbar>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  toast: state.toast,
});

export const ToastComponent = connect(mapStateToProps)(Component);
