import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { z } from "zod";
import React, { useState } from "react";
import work1 from "./assets/images/work-1.png";
import work2 from "./assets/images/work-2.png";
import work3 from "./assets/images/work-3.png";
import work4 from "./assets/images/work-4.png";
import work5 from "./assets/images/work-5.png";
import work6 from "./assets/images/work-6.png";
import serviceimg1 from "./assets/images/service-img-1.png";
import serviceimg2 from "./assets/images/service-img-2.png";
import serviceimg3 from "./assets/images/service-img-3.png";
import serviceimg from "./assets/images/service-img.png";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  invalid_type_error,
  required_error,
  short_value_message,
  validation_error_message,
} from "@get-busy/common";
import { executePost, showErrorToast } from "../../services/http-services";
import {
  cancel_line,
  siteLogo_blue_sm,
  verified,
  close,
} from "./assets/public_api";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { SiteLogoIcon } from "@get-busy/assets";

const loyalCustomers = require("../../../../assets/images/img.png");
const free = require("../../../../assets/images/free.png");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  p: 3,
};

type FormData = {
  email: string;
  name: string;
  phoneNumber: string;
  services: string;
  city: string;
  [key: string]: string; // Index signature
};

export const HomeLandingPageComponent = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  function handleEmailChange(event: any) {
    setEmail(event.target.value);
    setEmailErr("");
  }
  function onClickGetStarted(evt) {
    evt.preventDefault();
    if (!email) {
      handleScrollOnTop();
      setEmailErr("Please enter your email or phonenumber");
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (email.includes("@")) {
      if (!validateEmail(email)) {
        setEmailErr("Please enter your email");
        return;
      }
      window.location.replace(
        `https://www.getbusy.info/get-busy/public/signup?email=${email}&referralCode=${urlParams.get(
          "referralCode"
        )}`
      );
    } else if (email.length !== 10) {
      setEmailErr("Please enter valid email or phonenumber");
    } else {
      window.location.replace(
        `https://www.getbusy.info/get-busy/public/signup?phoneNumber=${email}&referralCode=${urlParams.get(
          "referralCode"
        )}`
      );
    }
  }

  //for scroll on top
  const handleScrollOnTop = () => {
    document
      .getElementById("login-form")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <div className="main-wrapper min-h-screen flex flex-col overflow-hidden landing_page max-w-[550px] mx-auto">
      <section className="capmp-main">
        <Box className="flex justify-center site-logo">
          <div className="w-100 site-logo__wrap bg-primary p-4 flex justify-center items-center ">
            <SiteLogoIcon />
          </div>
        </Box>

        <Box className="mt-8 px-3">
          <Box className="capmp-main__innerwrap">
            <div className="capmp-main__title">
              <h1 className="leading-[128%] text-[29px] text-center font-bold  text-primary-95">
                Do more jobs, <br /> make more money!
              </h1>
            </div>

            <Grid
              container
              className="mt-9 mb-6"
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item>
                <div className="flex justify-center img-box">
                  <img
                    className="object-contain object-center"
                    src={work1}
                    alt="work1"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="flex justify-center img-box">
                  <img
                    className="object-contain object-center"
                    src={work2}
                    alt="work2"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="flex justify-center img-box">
                  <img
                    className="object-contain object-center"
                    src={work3}
                    alt="work3"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="flex justify-center img-box">
                  <img
                    className="object-contain object-center"
                    src={work4}
                    alt="work4"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="flex justify-center img-box">
                  <img
                    className="object-contain object-center"
                    src={work5}
                    alt="work5"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="flex justify-center img-box">
                  <img
                    className="object-contain object-center"
                    src={work6}
                    alt="work6"
                  />
                </div>
              </Grid>
            </Grid>

            <p className="text-lg text-center text-black">
              Offer your clients an easy way to <br /> schedule appointments,{" "}
              <b>for free</b>.
            </p>

            <div id="login-form" className="mt-6">
              <form onSubmit={onClickGetStarted} className="add-service-form">
                <div className="flex field-wrap space-x-2">
                  <div className="flex-1 field">
                    <TextField
                      placeholder="Email or Phonenumber"
                      label="Email or Phonenumber"
                      variant="outlined"
                      name="Email Name"
                      onChange={handleEmailChange}
                      value={email}
                      fullWidth
                      type="text"
                      size="small"
                      InputLabelProps={{
                        size: "small",
                      }}
                      helperText={emailErr || ""}
                      error={!!emailErr}
                    />
                  </div>

                  <div className="field">
                    <Button
                      className=" bg-primary-60 font-medium text-sm  py-2 px-2.5 w-full"
                      variant="contained"
                      type="submit"
                      color="primary"
                    >
                      Get Started
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="mt-2 flex items-center justify-center text-[13px] space-x-0.5">
              {cancel_line}
              <p className="m-0">No credit card required</p>
            </div>
          </Box>
          <Box className="image-text-sec mt-12 mb-8 space-y-12">
            <div className="pb-0">
              <h2 className="title leading-[128%] text-[29px]  font-bold text-center text-neutral my-3 ">
                Worried about not earning enough each month?
              </h2>
              <h2 className="title text-[15px] m-0 leading-normal font-normal text-center text-neutral mb-5">
                Discover how GetBusy can help you build a loyal customer base
                and generate a predictable income each month.
              </h2>
              <div className="flex justify-center image-box rounded-xl overflow-hidden mb-10">
                <img src={loyalCustomers} className="w-full" />
              </div>
            </div>
            <div>
              <h3 className="leading-[128%] text-[29px] font-bold text-neutral my-3 text-center">
                Generate $50,000 Income with Just 50 Clients.
              </h3>
              <p className="text-center text-[15px]  m-0">
                Stop chasing random appointments on gig platforms. With a
                monthly recurring service priced at $150, acquiring just 50
                loyal clients can yield $54,000 annually, even if only 60% of
                them engage each month. Simplify booking for your customers with
                GetBusy's free booking page, easily shareable via web links,
                WhatsApp, and text messages. Effortlessly track the value of
                each customer.
              </p>
              <div className="mt-4 space-y-2.5 btn-wrap text-center">
                <Button
                  variant="outlined"
                  className="hover:shadow-lg flex items-center w-auto border-primary-60 pl-4 pr-4 pt-1.5 pb-1.5 text-center"
                  onClick={onClickGetStarted}
                >
                  Get Started
                </Button>
              </div>
            </div>
            <div className="free">
              <h3 className="leading-[128%] text-[29px] my-3  font-bold text-neutral text-center">
                Keep 100% of your earnings - GetBusy charges zero commissions.
              </h3>
              <div className="flex justify-center image-box rounded-xl overflow-hidden">
                <img
                  className="w-full max-w-[350px] rounded-lg object-contain object-center"
                  src={free}
                  alt="Image"
                />
              </div>
              <p className="text-center text-[15px]">
                Effortlessly manage appointments and scale your service business
                with our free booking page. Focus on providing exceptional
                service to your customers and maximize your earnings without any
                cost. Join us today to unlock your full potential – no credit
                card required.
              </p>
              <div className="mt-6 space-y-2.5 >  +  btn-wrap  text-center">
                <Button
                  variant="outlined"
                  className="hover:shadow-lg flex items-center w-auto border-primary-60 pl-4 pr-4 pt-1.5 pb-1.5 "
                  onClick={onClickGetStarted}
                >
                  Get Started
                </Button>
              </div>
            </div>
            <div className="">
              <h3 className="leading-[128%] text-[29px] font-bold text-neutral m-0 text-center">
                Grow your clientele effortlessly with GetBusy's marketing
                materials.
              </h3>
              <div className="flex justify-center image-box rounded-xl overflow-hidden">
                <img
                  className="w-full object-contain object-center"
                  src={serviceimg3}
                  alt="Image"
                />
              </div>
              <p className="text-center text-[15px]">
                Unlock the full potential of your existing client network with
                GetBusy's seamless referral system. Easily order print marketing
                materials like door hangers, flyers, and referral cards, all
                featuring your GetBusy QR codes, directly from the app. Fill
                your calendar by distributing these materials to neighbors of
                existing clients during your service visits.
              </p>
              <div className="mt-6 space-y-2.5 >  +  btn-wrap  text-center">
                <Button
                  variant="outlined"
                  className="hover:shadow-lg flex items-center w-auto border-primary-60 pl-4 pr-4 pt-1.5 pb-1.5"
                  onClick={onClickGetStarted}
                >
                  Get Started
                </Button>
              </div>
            </div>
            <Box>
              <h2 className="leading-[128%] text-[29px] font-bold text-center mb-0 ">
                What other service providers are saying about GetBusy
              </h2>
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                loop={true}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                speed={1000}
              >
                <SwiperSlide>
                  <div className="testimonial-item">
                    <div className="testimonial-item-inner bg-white border pl-6 pr-6 pt-9 pb-11 rounded-2xl">
                      <div className="testimonial_content pb-11">
                        <p className="text-lg text-purple-70">
                          As a service provider, time is crucial. GetBusy has
                          become my go-to solution for efficient scheduling and
                          client engagement. The positive impact on my business
                          is undeniable.
                        </p>
                      </div>
                      <h5 className="text-lg leading-normal font-bold text-purple-80">
                        Julian Amal
                      </h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-item">
                    <div className="testimonial-item-inner bg-white border pl-6 pr-6 pt-9 pb-11 rounded-2xl">
                      <div className="testimonial_content pb-11">
                        <p className="text-lg text-purple-70">
                          I've tried various business tools, but GetBusy stands
                          out. It not only helps me stay organized but also
                          enhances client communication. The result? Satisfied
                          customers and increased bookings!
                        </p>
                      </div>
                      <h5 className="text-lg leading-normal font-bold text-purple-80">
                        Rachel Kwan
                      </h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-item">
                    <div className="testimonial-item-inner bg-white border pl-6 pr-6 pt-9 pb-11 rounded-2xl">
                      <div className="testimonial_content pb-11">
                        <p className="text-lg text-purple-70">
                          GetBusy's unique approach to client accessibility is a
                          game-changer. The personalized QR code gifts are a hit
                          with my clients, and it has significantly increased
                          their engagement with my booking page.
                        </p>
                      </div>
                      <h5 className="text-lg leading-normal font-bold text-purple-80">
                        Mellisa Lopez
                      </h5>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Box>
          </Box>
        </Box>
      </section>

      <p className="bg-primary-60 m-0 p-3.5 bg-primary text-center text-xs text-white">
        Copyright 2024 Getbusy. All Rights Reserved
      </p>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-white p-6 modal"
      >
        <Box sx={style} className="rounded">
          <div className="verified-icon flex justify-center">
            <div className="w-[55px] ">{verified}</div>
          </div>

          <div className="modal__title-desc  space-y-2.5 > * + * mt-6">
            <h2 className="text-center text-neutral font-bold text-xl">
              Thank you for Details! <br /> You're now on our waiting list.
            </h2>
            <p className="text-center text-neutral text-sm">
              Your request is in the queue, and our team is diligently working
              on it. We appreciate your understanding.
            </p>
          </div>

          <div className="modal__close mt-10">
            <Link
              className="flex justify-center items-center text-primary-90 hover:text-primary-60 text-sm font-medium no-underline  cursor-pointer space-x-2 > * + *"
              onClick={handleClose}
            >
              <div className="icon-sm">{close}</div>
              <span>Close</span>
            </Link>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
