export const sideBarMenuItems = [{
  title: 'Home',
  id: 'home',
},
{
  title: 'About',
  id: 'about',
},
{
  title: 'Contact',
  id: 'contact',
},
{
  title: 'Login',
  id: 'login',
  redirect: '/get-busy/login',
},
{
  title: 'Signup',
  id: 'Signup',
  redirect: '/get-busy/signup',
}];
