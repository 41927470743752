export const routeConstant = {
  initialHome: {
    id: "getBusy",
    path: "/",
    title: "Get Busy",
  },
  login: {
    id: "home",
    path: "",
    title: "home",
  },
  form: {
    id: "form",
    path: "form",
    title: "form",
  },
  campaign: {
    id: "campaign",
    path: "campaign",
    title: "campaign",
  },
  home: {
    id: "home-cleaning",
    path: "home-cleaning",
    title: "home-cleaning",
  },
  generic: {
    id: "generic",
    path: "generic",
    title: "generic",
  },
  default: {
    id: "default",
    path: "*",
    title: "home",
    to: "",
    redirect: true,
  },
};
