import { Button, Input, Box, TextField, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { CancelIcon } from "./assets/icons/landing-page.icons";
import { Logo } from "./assets/icons/landing-page.icons";
import Earning2 from "./assets/images/1_Cropped.png";
import Earning1 from "./assets/images/Earning1.png";
import PrintMaterials from "./assets/images/print_materials.png";
import LawnServices from "./assets/images/landingPageSwiper/LawnServices.png";
import HomeCare from "./assets/images/landingPageSwiper/HomeCare.png";
import MachineRepair from "./assets/images/landingPageSwiper/MachineRepair.png";
import MassageServices from "./assets/images/landingPageSwiper/MassageServices.png";
import PetGrooming from "./assets/images/landingPageSwiper/PetGrooming.png";
import SalonServices from "./assets/images/landingPageSwiper/SalonServices.png";
import Cleaning from "./assets/images/landingPageSwiper/CleaningServices.png";
import Quote from "./assets/images/Quote.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import "./assets/style/landing-page.conatiners.style.css";
import { useState } from "react";

export const LandingPageContainers = () => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  //for scroll on top
  const handleScrollOnTop = () => {
    document
      .getElementById("login-form")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  function handleEmailChange(event: any) {
    setEmail(event.target.value);
    setEmailErr("");
  }

  function onClickGetStarted(evt) {
    evt.preventDefault();
    if (!email) {
      handleScrollOnTop();
      setEmailErr("Please enter your email or phonenumber");
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (email.includes("@")) {
      if (!validateEmail(email)) {
        setEmailErr("Please enter your email");
        return;
      }
      window.location.replace(
        `https://www.getbusy.info/get-busy/public/signup?email=${email}&referralCode=${urlParams.get(
          "referralCode"
        )}`
      );
    } else if (email.length !== 10) {
      setEmailErr("Please enter valid email or phonenumber");
    } else {
      window.location.replace(
        `https://www.getbusy.info/get-busy/public/signup?phoneNumber=${email}&referralCode=${urlParams.get(
          "referralCode"
        )}`
      );
    }
  }
  function onClickLogin(evt) {
    evt.preventDefault();
    window.location.replace("https://www.getbusy.info/get-busy/public/login");
  }

  return (
    <div className=" max-w-[450px] mx-auto border border-solid border-slate-300">
      <header className="py-4 px-6 bg-[#1A5CB2] relative z-10 max-w-[550px] mx-auto">
        <div className="flex items-center justify-center">
          <Logo />
        </div>
      </header>

      <section className="w-full h-full bg-primary-light mb-14 px-3 pt-4 pb-16  landingpage-rounded-earnings landingpage-rounded rounded-b-[60px]">
        <div className="text-center overflow-hidden mt-2">
          <h1 className="text-2xl my-0 pb-1 font-normal uppercase">
            Do more jobs.
          </h1>
          <h1 className="text-2xl my-0 pb-8 uppercase"> Make more money!</h1>
          <Box className="">
            <Swiper
              className=" pb-20 "
              spaceBetween={40}
              pagination={true}
              slidesPerView={2.1}
              centeredSlides={true}
              centeredSlidesBounds={true}
              loop={true}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              speed={1000}
            >
              <SwiperSlide className=" ">
                <Box className="">
                  <img
                    className="bg-[#F6FAFF] rounded-2xl w-[180px] "
                    src={Cleaning}
                    alt="Cleaning"
                  />
                </Box>
              </SwiperSlide>
              <SwiperSlide className=" ">
                <Box className="">
                  <img
                    className="bg-[#F6FAFF] rounded-2xl w-[180px] "
                    src={LawnServices}
                    alt="JobAdult"
                  />
                </Box>
              </SwiperSlide>
              <SwiperSlide className=" ">
                <Box className="">
                  <img
                    className="bg-[#F6FAFF] rounded-2xl w-[180px] "
                    src={HomeCare}
                    alt="JobAdult"
                  />
                </Box>
              </SwiperSlide>{" "}
              <SwiperSlide className=" ">
                <Box className="">
                  <img
                    className="bg-[#F6FAFF] rounded-2xl w-[180px] "
                    src={MachineRepair}
                    alt="JobAdult"
                  />
                </Box>
              </SwiperSlide>
              <SwiperSlide className=" ">
                <Box className="">
                  <img
                    className="bg-[#F6FAFF] rounded-2xl w-[180px] "
                    src={MassageServices}
                    alt="Services"
                  />
                </Box>
              </SwiperSlide>
              <SwiperSlide className=" ">
                <Box className="">
                  <img
                    className="bg-[#F6FAFF] rounded-2xl w-[180px] "
                    src={PetGrooming}
                    alt="JobAdult"
                  />
                </Box>
              </SwiperSlide>
              <SwiperSlide className=" ">
                <Box className="">
                  <img
                    className="bg-[#F6FAFF] rounded-2xl w-[180px] "
                    src={SalonServices}
                    alt="JobAdult"
                  />
                </Box>
              </SwiperSlide>
            </Swiper>
          </Box>
        </div>
        <div className="text-center pt-2 space-y-3">
          <h3 className="text-lg font-normal my-0">
            Offer clients an easy way to schedule appointments,{" "}
            <span className="text-lg font-bold">for free.</span>
          </h3>
          <div
            className="flex gap-2 items-start justify-center"
            id="login-form"
          >
            <TextField
              placeholder="Email or Phonenumber"
              label="Email or Phonenumber"
              variant="outlined"
              name="Email Name"
              onChange={handleEmailChange}
              value={email}
              type="text"
              size="small"
              InputLabelProps={{
                size: "small",
                // shrink: true,
              }}
              helperText={emailErr || ""}
              error={!!emailErr}
              className="flex-1"
            />
            <Button
              className=" bg-[#1A5CB2] text-white hover:bg-[#1A5CB2] w-[120px]"
              onClick={onClickGetStarted}
            >
              Get Started
            </Button>
          </div>
          <div className="flex justify-center align-middle mt-3.5">
            <CancelIcon />
            <h5 className="text-xs font-normal my-0 ml-1 tracking-wide">
              No credit card required
            </h5>
          </div>
          <div className="flex justify-center ">
            Already have an account?
            <Link onClick={onClickLogin} className="pl-2">
              Login here!
            </Link>
          </div>
        </div>
      </section>

      <section className="w-full h-full flex flex-col gap-12">
        <div className="bg-[#1A5CB2] px-4 py-16 rounded-b-[60px] rounded-t-[60px]  landingpage-rounded">
          <div>
            <h2 className="text-[24px] font-bold my-0 text-white text-center uppercase">
              Not earning enough each month?
            </h2>
            <h3 className="text-base font-normal my-0 text-white text-center  mt-2.5">
              Discover how GetBusy can help you build a loyal customer base and
              generate a predictable income each month.
            </h3>
          </div>
          <div>
            <Box className="mt-[34px]">
              <img src={Earning2} alt="Earning2" className="w-full bg-white" />
              <h4 className="text-[22px] font-bold my-0 text-center uppercase justify-items-center text-white mt-3">
                Generate $54,000 with Just 50 Clients.
              </h4>
              <h3 className="text-base font-normal my-0 text-center text-white mt-1.5">
                Stop chasing random appointments on gig platforms. With a
                monthly recurring service priced at $150, acquiring just 50
                loyal clients can yield $54,000 annually, even if only 60% of
                them engage each month. Build your loyal clientele with
                GetBusy’s free booking page where you can effortlessly track the
                value of each customer. Simplify booking experience for your
                clients with easily shareable via web links, WhatsApp, and text
                messages.
              </h3>
              <Button
                className="flex justify-center gap-2 w-full items-center bg-transparent text-white mt-3 border border-white border-solid h-[43px]"
                onClick={onClickGetStarted}
              >
                Get Started
              </Button>
            </Box>
          </div>
        </div>
        <div className="bg-primary-light px-4 py-12 rounded-b-[60px] rounded-t-[60px] landingpage-rounded-earnings landingpage-rounded">
          <Box>
            <h4 className="text-[24px] font-bold my-0 text-[#1A5CB2] text-center uppercase">
              Keep 100% of your earnings
            </h4>
            <h3 className="text-base font-normal my-0 text-[#1A5CB2] text-center mt-2.5">
              GetBusy's booking page and calendar application are free to use,
              we charge zero comissions.
            </h3>
            <img src={Earning1} alt="Earning1" className="w-full mt-3" />
            {/* <h4 className="text-[22px] font-bold my-0 text-center uppercase justify-items-center text-[#1A5CB2] mt-3">
              Generate $54,000 with Just 50 Clients.
            </h4> */}
            <h3 className="text-base font-normal my-0 text-[#1A5CB2] mt-1.5 text-center">
              Effortlessly manage appointments and scale your service business
              with our free booking page. Focus on providing exceptional service
              to your customers and maximize your earnings without any cost.
              Join us today to unlock your full potential – no credit card
              required.
            </h3>
            <Button
              className="flex justify-center gap-2 w-full items-center bg-transparent text-[#1A5CB2] mt-3 border border-[#1A5CB2] border-solid h-[43px]"
              onClick={onClickGetStarted}
            >
              Get Started
            </Button>
          </Box>
        </div>
        <div className="bg-[#1A5CB2] px-4 py-12 rounded-b-[60px] rounded-t-[60px] landingpage-rounded">
          <Box className="space-y-3">
            <h4 className="text-[24px] font-bold my-0 text-white text-center uppercase">
              GROW EFFORTLESSLY WITH GETBUSY'S MARKETING MATERIALS.
            </h4>
            <img src={PrintMaterials} alt="PrintMaterials" className="w-full" />

            <h3 className="text-base font-normal my-0 text-center text-white mt-1.5">
              Unlock the full potential of your existing client network with
              GetBusy's seamless referral system. Easily order print marketing
              materials like door hangers, flyers, and referral cards, all
              featuring your GetBusy QR codes, directly from the app. Fill your
              calendar by distributing these materials to neighbors of existing
              clients during your service visits.
            </h3>
            <Button
              className="flex justify-center gap-2 w-full items-center bg-transparent text-white mt-6 border border-white border-solid h-[43px]"
              onClick={onClickGetStarted}
            >
              Get Started
            </Button>
          </Box>
        </div>
      </section>

      <section className="mt-12 text-center pb-16  overflow-hidden">
        <h3 className="text-2xl font-bold my-0 pb-4">
          What other service providers are saying about GetBusy
        </h3>
        <Box className="w-[340px] mx-auto">
          <Swiper
            className="pt-5 pb-12 overflow-visible"
            spaceBetween={30}
            pagination={true}
            slidesPerView={1}
            centeredSlides={true}
            centeredSlidesBounds={true}
            loop={true}
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={3000}
          >
            <SwiperSlide className="relative landingpage-rounded landingpage-rounded-slide ">
              <img
                className="absolute text-center -top-3.5 z-10 left-0 right-0 mx-auto"
                src={Quote}
                alt="Quote"
              />
              <Box className="pt-10 px-8 pb-9 bg-[#1A5CB2] rounded-[20px] ">
                <div className="w-full">
                  <h2 className="text-[20px] text-center text-white font-normal mb-12">
                    As a service provider, time is crucial. GetBusy has become
                    my go-to solution for efficient scheduling and client
                    engagement. The positive impact on my business is
                    undeniable.
                  </h2>
                  <h3 className="text-[18px] text-center text-white font-bold my-0">
                    Julian Amal
                  </h3>
                </div>
              </Box>
            </SwiperSlide>
            <SwiperSlide className="relative landingpage-rounded landingpage-rounded-slide landingpage-rounded-light-slide ">
              <img
                className="absolute text-center -top-3.5 z-10 left-0 right-0 mx-auto"
                src={Quote}
                alt="Quote"
              />
              <Box className="pt-10 px-8 pb-9 bg-[#D2E3F9] rounded-[20px]">
                <div className="w-full">
                  <h2 className="text-[20px] text-center text-[#1A5CB2] font-normal pb-[67px] my-0">
                    I've tried various business tools, but GetBusy stands out.
                    It not only helps me stay organized but also enhances client
                    communication. The result? Satisfied customers and increased
                    bookings!
                  </h2>
                  <h3 className="text-[18px] text-center text-[#1A5CB2] font-bold my-0">
                    Rachel Kwan
                  </h3>
                </div>
              </Box>
            </SwiperSlide>
            <SwiperSlide className="relative landingpage-rounded landingpage-rounded-slide ">
              <img
                className="absolute text-center -top-3.5 z-10 left-0 right-0 mx-auto"
                src={Quote}
                alt="Quote"
              />
              <Box className="pt-10 px-8 pb-9 bg-[#1A5CB2] rounded-[20px] ">
                <div className="w-full">
                  <h2 className="text-[20px] text-center text-white font-normal pb-[67px] my-0">
                    GetBusy's unique approach to client accessibility is a
                    game-changer. The personalized QR code gifts are a hit with
                    my clients, and it has significantly increased their
                    engagement with my booking page.
                  </h2>
                  <h3 className="text-[18px] text-center text-white font-bold my-0">
                    Mellisa Lopez
                  </h3>
                </div>
              </Box>
            </SwiperSlide>
          </Swiper>
        </Box>
      </section>

      <footer className="bg-[#1A5CB2] py-3.5">
        <p className="text-white m-0 text-center text-xs">
          Copyright 2024 Getbusy. All Rights Reserved
        </p>
      </footer>
    </div>
  );
};
