import React, { PureComponent } from 'react';
import { AboutComponent } from './about.component';
import './style/about.styles.css';

export class AboutContainer extends PureComponent {
  render() {
    return (
      <AboutComponent
        {...this.state}
      />
    );
  }
}

