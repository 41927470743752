import { MediaQueryProps, WithTheme } from '@get-busy/common';
import { withMediaQueries, withTheme } from '@get-busy/components';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { Button, Grid, Toolbar, Typography, TextField, ThemeProvider, Theme } from '@mui/material';
import { Box, createTheme } from '@mui/system';
import React, { PureComponent } from 'react';
import './style/questions.styles.css';
import axios from 'axios';
declare global {
  interface Window {
    fbq: (a: string, b: string) => void;
  }
}
const elevateBusiness = require('../../../../static/images/elevate-business.png');
const schedulingImage = require('../../../../static/images/Scheduling-image.webp');

class Component extends PureComponent<MediaQueryProps & WithTheme, { email: string; }> {
  constructor(props) {
    super(props)
    this.state = {
      email: ""
    }
  }

  onClickLogin = async () => {
    if (!this.state.email) {
      return;
    }
    const data1 = await axios.post("/getbusy/v1/trial-user", {
      email: this.state.email
    });
    window.fbq('track', 'Lead');
    window.open(`/form?id=${data1.data.id}`, "_self");
  };
  static onClickSignUp = () => {
    window.open('/get-busy/public/signup', '_target');
  };


  render() {
    const { isExtraSmall, isMedium, isLarge, isExtraLarge } = this.props;
    return (
      <div className="position-relative h-100">
        <Box
          id="home"
          className="intro__container common__container w-100 h-inherit"
        >
          <Toolbar />
          <Grid
            container
            className="w-100 h-80 intro__container-div"
            alignItems="center"
          >
            <Grid
              item
              lg={6}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography
                    color="white"
                    align="center"
                    variant="h4"
                    className="fw-500 pt-5 "
                  >
                    Do more jobs,
                  </Typography>
                  <Typography
                    color="white"
                    align="center"
                    variant="h4"
                    className={`fw-500 pt-2 ${(isLarge || isExtraLarge) ? "pb-4" : ""}`}
                  >
                    Make more money!
                  </Typography>
                </Grid>
                {!(isLarge || isExtraLarge) && (
                  <Grid
                    item
                    xs={12}
                    className="text-align-center pt-5"
                  >
                    <img
                      className="intro__image text-align-center"
                      src={schedulingImage}
                      alt="Elevate Business"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography
                    color="white"
                    align="center"
                    variant={`${(isExtraLarge) ? 'h6' : 'h6'}`}
                    className="fw-500 mb-3"
                  >
                    Offer clients an easy way to schedule appointments, for free.
                  </Typography>
                  <Typography
                    color="white"
                    align="center"
                    variant={`${(isExtraLarge) ? 'h6' : 'h6'}`}
                    className="fw-500 mb-3"
                  >
                    <b>No credit card required</b>
                  </Typography>
                  {/* <Typography
                    color="white"
                    align="center"
                    variant={`${(isExtraLarge) ? 'h6' : 'h6'}`}
                    className="fw-500 pt-5 "
                  >
                    Please complete this questionnaire to be added to our wait list.
                  </Typography> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="text-align-center"
                >
                  {/* <Button
                    onClick={Component.onClickContactUs}
                    variant="contained"
                    color="secondary"
                    className="z-10"
                    {...(isExtraSmall && {
                      fullWidth: true,
                    })}
                  >
                    Get Busy Inquiry
                  </Button> */}
                  {/* <ThemeProvider theme={customTheme(this.props.theme)}> */}
                  <TextField
                    label="Email Address"
                    value={this.state.email}
                    margin="dense"
                    className="mb-3 text-field"
                    style={{
                      color: " white",
                      // background: "white"
                    }}
                    InputLabelProps={{
                      classes: {
                        outlined: "text-white"
                      }
                    }}
                    inputProps={{
                      className: "test"
                    }}
                    InputProps={{
                      classes: {
                        input: "text-white"
                      }
                    }}
                    onChange={({ target: { value } }) => this.setState({ email: value })}
                    fullWidth
                    required
                  />
                  {/* </ThemeProvider> */}
                  <Button
                    onClick={this.onClickLogin}
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white'
                    }}
                    className="z-10"
                    {...(isExtraSmall && {
                      fullWidth: true,
                    })}
                  >
                    Start your free trial
                  </Button>
                  <Grid item xs={12}>
                    <Typography
                      color="white"
                      align="center"
                      variant={`${(isExtraLarge) ? 'h6' : 'h6'}`}
                      className="fw-500 pt-5 "
                    >
                      Signup today for early access, <b>Limited time only!</b>.
                    </Typography>
                    {/* <Typography
                    color="white"
                    align="center"
                    variant={`${(isExtraLarge) ? 'h6' : 'h6'}`}
                    className="fw-500 pt-5 "
                  >
                    Please complete this questionnaire to be added to our wait list.
                  </Typography> */}
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            {(isLarge || isExtraLarge) && (
              <Grid
                item
                xs={12}
                className="text-align-center"
                lg={6}
              >
                <img
                  className="intro__image text-align-center"
                  src={schedulingImage}
                  alt="Elevate Business"
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {
          (isMedium || isLarge || isExtraLarge) ? (
            <div className="intro__wave-medium">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill" />
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill" />
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill" />
              </svg>
            </div>
          ) : (
            <div className="custom-shape-divider-bottom-1678534451">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" />
              </svg>
            </div>
          )
        }
      </div>
    );
  }
}

export const IntroComponent = withTheme(withMediaQueries(Component));


