import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { merge } from "lodash";
import { toast } from "react-toastify";

export const showSuccessToast = (message: string | any, duration = 2000) => {
  toast.success(message, { autoClose: duration });
};

export const showErrorToast = (message: string | any, duration = 2000) => {
  toast.error(message, {
    autoClose: duration,
  });
};

axios.interceptors.request.use(
  (config: any) => config,
  (error: AxiosError) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    const { status, config, data } = response;
    const {
      showSuccessMessage = true,
      successMessages,
      successMessage,
      onSuccess,
      autoHideDuration,
      errorMessages,
      errorMessage,
      onError,
      redirectOnSuccess: { redirect = false, time = 2000 } = {},
    } = config;
    const successMessageToShow =
      successMessages?.[status] ??
      successMessage ??
      (onSuccess && onSuccess(response));
    if (showSuccessMessage && successMessageToShow) {
      showSuccessToast(successMessageToShow, autoHideDuration);
    }
    if (data?.status >= 200 || status <= 299) {
      if (redirect) {
        setTimeout(() => {
          window.location.reload();
        }, time);
      }
      return response;
    }
    return Promise.reject(
      data?.message ??
      errorMessages?.[status] ??
      errorMessage ??
      (onError && onError(data))
    );
  },
  (error: AxiosError<any, any>) => {
    const { config, response } = error;
    const {
      showErrorMessage = false,
      onError,
      errorMessage,
      errorMessages,
      autoHideDuration,
      redirectToLoginOnUnauthorize: { immediately = false, time = 5000 } = {},
    } = config as any;
    const errorMessagesToShow = merge(
      {
        401: "Unauthorized, please login and try again!!!",
        403: "Forbidden!!!",
      },
      errorMessages
    );
    let errorMesageToShow = errorMessage ?? (onError && onError(error));
    if (response) {
      const { status, data } = response;
      if (status >= 400 || status <= 409) {
        return response;
      }
      errorMesageToShow = errorMessagesToShow?.[status] ?? errorMesageToShow;
      if ([401].includes(status)) {
        // const { initialHome, open, login } = routeConstant;
        if (immediately) {
          // window.location.replace(
          //   `${window.location.origin}/${initialHome.path}/${open.path}/${login.path}`
          // );
        } else {
          // setTimeout(() => {
          //   window.location.replace(
          //     `${window.location.origin}/${initialHome.path}/${open.path}/${login.path}`
          //   );
          // }, time);
        }
      } else {
        errorMesageToShow =
          data?.message ??
          errorMesageToShow ??
          "Something went wrong, try after sometime!!!";
      }
    } else if (navigator.onLine) {
      errorMesageToShow = errorMesageToShow ?? "Something went wrong!!!";
    } else {
      errorMesageToShow = errorMesageToShow ?? "Network error!!!";
    }
    if (showErrorMessage && errorMesageToShow) {
      showErrorToast(errorMesageToShow, autoHideDuration);
    }
    return Promise.reject(errorMesageToShow);
  }
);

const executeHttp = <T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return axios(
    merge(
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      config
    )
  );
};

export const executeGet = <T>(
  url: string,
  data?: any,
  config?: Partial<AxiosRequestConfig>
): Promise<AxiosResponse<T>> =>
  executeHttp({
    url,
    method: "GET",
    data,
    ...config,
  });

export const executePost = <T>(
  url: string,
  data?: any,
  config?: Partial<AxiosRequestConfig>
): Promise<AxiosResponse<T>> =>
  executeHttp<T>({
    url,
    method: "POST",
    data,
    ...config,
  });

export const executePut = <T>(
  url: string,
  data?: any,
  config?: Partial<AxiosRequestConfig>
): Promise<AxiosResponse<T>> =>
  executeHttp<T>({
    url,
    method: "PUT",
    data,
    ...config,
  });

export const executeDelete = <T>(
  url: string,
  data?: any,
  config?: Partial<AxiosRequestConfig>
): Promise<AxiosResponse<T>> =>
  executeHttp<T>({
    url,
    method: "DELETE",
    data,
    ...config,
  });
