import { Store } from "@get-busy/pages";
import "animate.css";
import { AppComponent } from "app.component";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import { StyledEngineProvider } from "@mui/system";

const queryClient = new QueryClient({
  defaultOptions: { queries: {} },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <Provider store={Store}>
          <BrowserRouter>
            <AppComponent />
          </BrowserRouter>
        </Provider>
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
