import { useTheme } from '@emotion/react';
import React from 'react';

function withThemeComponent(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const theme = useTheme();
    return (
      <Component
        {...props}
        theme={theme}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const withTheme = withThemeComponent;
