/* eslint-disable no-underscore-dangle */

import { GetBusySaga, ReducerAction } from "@get-busy/common";
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Reducer,
  Store,
} from "redux";
import logger from "redux-logger";
import createSagaMiddleware, { Saga } from "redux-saga";
import { all, fork } from "redux-saga/effects";

export class AppStore {
  private store!: Store;

  constructor(
    private reducers: {
      [key: string]: Reducer<any, ReducerAction<any>>;
    },
    private sagas: {
      [key: string]: GetBusySaga;
    }
  ) {
    this.initializeStore();
  }

  getStore() {
    return this.store;
  }

  private initializeStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares: Middleware[] = [sagaMiddleware];
    if (process.env.NODE_ENV !== "production") {
      // middlewares.push(logger);
    }
    this.store = createStore(
      combineReducers(this.reducers),
      applyMiddleware(...middlewares)
    );
    sagaMiddleware.run(this.getRootSaga());
  }

  private getRootSaga(): Saga {
    const _this = this as any;
    return function* () {
      yield all([
        ...Object.values(_this.sagas as GetBusySaga[]).map((saga) =>
          fork(saga)
        ),
      ]);
    };
  }
}
