import { SideBarComponent, withTheme } from '@get-busy/components';
import React, { PureComponent } from 'react';
import { AboutContainer } from '../about/about.container';
import { ContactUsContainer } from '../contact-us/contact-us.container';
import { FooterContainer } from '../footer/footer.container';
import { IntroComponent } from '../questions/intro.component';
import { RevenueComponent } from '../questions/revenue.component';

class Component extends PureComponent {
  render() {
    return (
      <>
        <SideBarComponent />
        <div className="h-100">
          <IntroComponent />
          <RevenueComponent />
          <AboutContainer />
          <ContactUsContainer />
          <FooterContainer />
        </div>
      </>
    );
  }
}

export const HomeComponent = withTheme(Component);
