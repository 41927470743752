import { MediaQueryProps } from '@get-busy/common';
import { Alert, Button, CircularProgress, Grid, Snackbar, TextField, Typography } from '@mui/material';
import React, { PureComponent } from 'react';
// eslint-disable-next-line import/no-cycle
import { ContactUsContainerState } from './contact-us.container';

const contactUs = require('../../../../static/images/contact-us.avif');

interface ContactUsComponentProps extends ContactUsContainerState, MediaQueryProps {
  onSendEmail: () => void;
  onChange: (name: string, value: string) => void;
  onCloseSnackbar: () => void;
}

export class ContactUsComponent extends PureComponent<ContactUsComponentProps> {
  render() {
    const {
      onSendEmail, onChange, name, email, message,
      subject, errors, sentStatus, hideSnackbar, onCloseSnackbar,
      isLarge, isExtraLarge,
      isMedium,
    } = this.props;
    return (
      <div id="contact" className="pt-6 w-100 d-flex flex-column align-items-center common__container position-relative">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="mb-5 contact-us__container"
        >
          <Grid
            item
            xs={12}
            lg={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <img
              src={contactUs}
              alt="contactUs"
              className="contact-us__image"
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <Grid className="mb-5">
              <Typography variant="h4" align="center">
                Contact Us
              </Typography>
            </Grid>
            <Grid>
              <TextField
                label="Enter Your Name"
                variant="outlined"
                margin="dense"
                className="mb-3"
                FormHelperTextProps={{
                  error: !!errors?.name,
                }}
                {... !!errors?.name && {
                  helperText: 'Name must be at least 5 characters long',
                }}
                value={name}
                onChange={({ target: { value } }) => onChange('name', value)}
                fullWidth
                required
              />
            </Grid>
            <Grid>
              <TextField
                label="Enter Your Email"
                variant="outlined"
                margin="dense"
                className="mb-3"
                FormHelperTextProps={{
                  error: !!errors?.email,
                }}
                {... !!errors?.email && {
                  helperText: 'Enter correct email id',
                }}
                value={email}
                onChange={({ target: { value } }) => onChange('email', value)}
                fullWidth
                required
              />
            </Grid>
            <Grid>
              <TextField
                label="Enter Your Subject"
                variant="outlined"
                margin="dense"
                className="mb-3"
                FormHelperTextProps={{
                  error: !!errors?.subject,
                }}
                {... !!errors?.subject && {
                  helperText: 'Subject must be at least 5 characters long',
                }}
                value={subject}
                onChange={({ target: { value } }) => onChange('subject', value)}
                fullWidth
                required
              />
            </Grid>
            <Grid>
              <TextField
                label="Enter Your Message"
                variant="outlined"
                minRows="6"
                maxRows="20"
                margin="dense"
                className="mb-4"
                value={message}
                FormHelperTextProps={{
                  error: !!errors?.message,
                }}
                {... !!errors?.message && {
                  helperText: 'Message must be at least 5 characters long',
                }}
                onChange={({ target: { value } }) => onChange('message', value)}
                fullWidth
                multiline
                required
              />
            </Grid>
            <Grid>
              <Button
                color="primary"
                variant="contained"
                onClick={onSendEmail}
                fullWidth
              >
                {sentStatus === 'loading' && <CircularProgress size="1.5rem" className="mr-2" />}
                Send Email
              </Button>
              {sentStatus === 'success'
              && (
                <Snackbar
                  open={!hideSnackbar}
                  autoHideDuration={6000}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  onClose={onCloseSnackbar}
                >
                  <Alert severity={sentStatus as any}>
                    You message has been sent, you will be contacted shortly!!!
                  </Alert>
                </Snackbar>
              )}
              {sentStatus === 'error'
              && (
                <Snackbar
                  open={!hideSnackbar}
                  autoHideDuration={6000}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  onClose={onCloseSnackbar}
                >
                  <Alert severity={sentStatus as any}>
                    Failed to send email please check contact form details
                  </Alert>
                </Snackbar>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

