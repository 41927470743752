import { GetBusyRoutes } from "@get-busy/common";
import { NotFoundComponent } from "@get-busy/components";
import {
  GenericCampaign,
  GetBusyContainer,
  HomeCleaningCampaign,
  HomeContainer,
  HomeLandingPageComponent,
  LandingPageContainers,
} from "@get-busy/pages";
import { FormComponent } from "pages/lib/fragments/form/form.component";
import { routeConstant } from "./route.constants";

export const getBusyRoutes: GetBusyRoutes[] = [
  {
    path: routeConstant.initialHome.path,
    id: routeConstant.initialHome.id,
    key: routeConstant.initialHome.id,
    title: routeConstant.initialHome.title,
    element: GetBusyContainer,
    childRoutes: [
      {
        path: routeConstant.login.path,
        id: routeConstant.login.id,
        key: routeConstant.login.id,
        title: routeConstant.login.title,
        element: LandingPageContainers,
      },
      {
        path: routeConstant.form.path,
        id: routeConstant.form.id,
        key: routeConstant.form.id,
        title: routeConstant.form.title,
        element: FormComponent,
      },
      {
        path: routeConstant.campaign.path,
        id: routeConstant.campaign.id,
        key: routeConstant.campaign.id,
        title: routeConstant.campaign.title,
        childRoutes: [
          {
            path: routeConstant.home.path,
            id: routeConstant.home.id,
            key: routeConstant.home.id,
            title: routeConstant.home.title,
            element: HomeCleaningCampaign,
          },
          {
            path: routeConstant.generic.path,
            id: routeConstant.generic.id,
            key: routeConstant.generic.id,
            title: routeConstant.generic.title,
            element: GenericCampaign,
          },
        ],
      },
      {
        path: routeConstant.default.path,
        id: routeConstant.default.id,
        key: routeConstant.default.id,
        title: routeConstant.default.title,
        to: routeConstant.default.to,
        element: NotFoundComponent,
      },
    ],
  },
  {
    path: routeConstant.default.path,
    id: routeConstant.default.id,
    key: routeConstant.default.id,
    title: routeConstant.default.title,
    to: `${routeConstant.initialHome.path}/${routeConstant.default.to}`,
    element: NotFoundComponent,
  },
];
