import { MediaQueryProps, sideBarMenuItems } from '@get-busy/common';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import React, { PureComponent } from 'react';
import { withMediaQueries } from '../media-query/media-query.component';
import './styles/side-bar.styles.css';

const logoNormal = require('../../../static/images/get-busy-new-logo.png');
const logoWhite = require('../../../static/images/get-busy-new-logo-white.png');

interface HomeComponentState {
  mobileOpen: boolean;
}

class Component extends PureComponent<MediaQueryProps, HomeComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  handleDrawerToggle = () => {
    const { mobileOpen } = this.state;
    this.setState({
      mobileOpen: !mobileOpen,
    } as any);
  };

  onClickMenuItem = (id: string, redirect?: string) => {
    if (redirect) {
      window.open(redirect, '_target');
      return;
    }
    setTimeout(() => document?.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    }), 300);
    this.handleDrawerToggle();
  };

  render() {
    const { mobileOpen } = this.state;
    const { isExtraSmall } = this.props;
    return (
      <Box className="d-flex side-bar__container">
        <CssBaseline />
        <AppBar
          component="nav"
          className="side-bar__Appbar"
          sx={{
            boxShadow: 'none',
          }}
        >
          <Toolbar className="justify-content-between">
          <Box
              className={`${
                isExtraSmall && "w-100 d-flex justify-content-center"
              }`}
              sx={{
                display: {
                  sm: "none",
                },
                // width: {
                //   sm: "100%",
                // },
              }}
            >
              <img src={logoWhite} alt="Get Busy" className="side-bar__logo" />
            </Box>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              sx={{
                display: {
                  sm: 'none',
                },
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton> */}
            <Box sx={{
              flexGrow: 1,
              display: {
                xs: 'none', sm: 'block',
              },
            }}
            >
              <img
                src={logoWhite}
                alt="Get Busy"
                className="side-bar__logo"
              />
            </Box>
            <Box sx={{
              display: {
                xs: 'none', sm: 'block',
              },
            }}
            >
              {sideBarMenuItems.map(({ id, title, redirect }) => (
                <Button
                  key={id}
                  sx={{
                    color: '#fff',
                    ...(!isExtraSmall && {
                      // fontSize: '20px',
                    }),
                  }}
                  color="warning"
                  onClick={() => this.onClickMenuItem(id, redirect)}
                >
                  {title}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            anchor="right"
            sx={{
              display: {
                xs: 'block', sm: 'none',
              },
              '& .MuiDrawer-paper': {
                width: '100%',
                elevation: 0,
              },
            }}
            className="w-100"
          >
            <Box className="text-align-left">
              <List className="p-1">
                <ListItem className="justify-content-between">
                  <img
                    src={logoNormal}
                    alt="Get Busy"
                    className="side-bar__logo"
                  />
                  <IconButton onClick={this.handleDrawerToggle}>
                    <CloseIcon />
                  </IconButton>
                </ListItem>
                {sideBarMenuItems.map(({ id, title, redirect }) => (
                  <ListItem key={id} disablePadding>
                    <ListItemButton onClick={() => this.onClickMenuItem(id, redirect)}>
                      <Typography variant="h6">
                        {title}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Box>
      </Box>
    );
  }
}

export const SideBarComponent = withMediaQueries(Component);
