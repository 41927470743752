import { MediaQueryProps, WithTheme } from '@get-busy/common';
import { withMediaQueries, withTheme } from '@get-busy/components';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { Button, Grid, Toolbar, Typography, TextField, ThemeProvider, Theme, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@mui/material';
import { Box, createTheme } from '@mui/system';
import React, { PureComponent } from 'react';
import axios from 'axios';

const logoWhite = require('../../../../static/images/get-busy-new-logo-white.png');
const schedulingImage = require('../../../../static/images/Scheduling-image.webp');

class Component extends PureComponent<MediaQueryProps & WithTheme, { name: string; city: string; services: string; phoneNumber: string; open: boolean }> {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            city: "",
            services: "",
            phoneNumber: "",
            open: false
        }
    }

    onClickAddToWaitlist = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get("id")!;
        if (!this.state.name && !this.state.city && !this.state.services && !this.state.phoneNumber && !id) {
            return;
        }

        await axios.post("/getbusy/v1/trial-user/existing", {
            id: parseInt(id),
            name: this.state.name,
            city: this.state.city,
            services: this.state.services,
            phoneNumber: this.state.phoneNumber,
        }).then((res) => {
            if (res.status >= 200 && res.status < 300) {
                this.setState({
                    open: true
                })
            }
        })
    };

    handleDiaglogClose = () => {
        this.setState({
            open: false
        });
        window.open(`/`, "_self");
    }


    render() {
        const { isExtraSmall, isMedium, isLarge, isExtraLarge } = this.props;
        return (
            <div className="position-relative h-100">
                <Box
                    id="home"
                    className="intro__container common__container w-100 h-inherit"
                >
                    <div className={`d-flex justify-content-center pt-4 ${(isLarge || isExtraLarge) ? 'pb-9 mb-9 pt-0' : "pb-4"}`} >
                        < img alt="getbusy" src={logoWhite} style={{
                            width: "143px"

                        }} />
                    </div>
                    <Grid
                        container
                        className="w-100 h-80 intro__container-div"
                        alignItems="center"
                    >{(isLarge || isExtraLarge) && (
                        <Grid
                            item
                            xs={12}
                            className="text-align-center"
                            lg={6}
                        >
                            <img
                                className="intro__image text-align-center"
                                src={schedulingImage}
                                alt="Elevate Business"
                            />
                        </Grid>
                    )}
                        <Grid
                            item
                            lg={6}
                        >
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                            >

                                {!(isLarge || isExtraLarge) && (
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-align-center pt-5"
                                    >
                                        <img
                                            className="intro__image text-align-center"
                                            src={schedulingImage}
                                            style={{
                                                width: "250px "
                                            }}
                                            alt="Elevate Business"
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Typography
                                        color="white"
                                        align="left"
                                        variant={`${(isExtraLarge) ? 'h6' : 'h6'}`}
                                        // variant="h6"
                                        className="fw-500 mb-3"
                                    >
                                        Please provide contact details:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Name"
                                        value={this.state.name}
                                        margin="dense"
                                        size={`${(isLarge || isExtraLarge) ? 'medium' : 'small'}`}
                                        className="mb-3 text-field"
                                        style={{
                                            color: " white",
                                            // background: "white"
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                outlined: "text-white"
                                            }
                                        }}
                                        inputProps={{
                                            className: "test"
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "text-white"
                                            }
                                        }}
                                        onChange={({ target: { value } }) => this.setState({ name: value })}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Phone Number"
                                        value={this.state.phoneNumber}
                                        margin="dense"
                                        className="mb-3 text-field"
                                        size={`${(isLarge || isExtraLarge) ? 'medium' : 'small'}`}
                                        style={{
                                            color: " white",
                                            // background: "white"
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                outlined: "text-white"
                                            }
                                        }}
                                        inputProps={{
                                            className: "test"
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "text-white"
                                            }
                                        }}
                                        onChange={({ target: { value } }) => this.setState({ phoneNumber: value })}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="text-align-center"
                                >
                                    <TextField
                                        label="Services offered"
                                        value={this.state.services}
                                        size={`${(isLarge || isExtraLarge) ? 'medium' : 'small'}`}
                                        margin="dense"
                                        className="mb-3 text-field"
                                        style={{
                                            color: " white",
                                            // background: "white"
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                outlined: "text-white"
                                            }
                                        }}
                                        inputProps={{
                                            className: "test"
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: "text-white"
                                            }
                                        }}
                                        onChange={({ target: { value } }) => this.setState({ services: value })}
                                        fullWidth
                                        required
                                    />
                                    <Grid item xs={12}>
                                        <TextField
                                            label="City"
                                            value={this.state.city}
                                            size={`${(isLarge || isExtraLarge) ? 'medium' : 'small'}`}
                                            margin="dense"
                                            className="mb-3 text-field"
                                            style={{
                                                color: " white",
                                                // background: "white"
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    outlined: "text-white"
                                                }
                                            }}
                                            inputProps={{
                                                className: "test"
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: "text-white"
                                                }
                                            }}
                                            onChange={({ target: { value } }) => this.setState({ city: value })}
                                            fullWidth
                                            required
                                        />
                                        <Button
                                            onClick={this.onClickAddToWaitlist}
                                            variant="contained"
                                            color="secondary"
                                            style={{
                                                color: 'white'
                                            }}
                                            className="z-10"
                                            {...(isExtraSmall && {
                                                fullWidth: true,
                                            })}
                                        >
                                            Add to waitlist
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleDiaglogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        Thank you for your interest!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            We've filled all current testing spots, but don't worry. We're expanding and will let you know when you can start using the product soon.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDiaglogClose}>close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const FormComponent = withTheme(withMediaQueries(Component));


