export const invalid_type_error = "Invalid type provided for this field";
export const required_error = "This field cannot be blank";
export const short_value_message = "Value is too short";
export const all_fiels_required_error = "All fiels are required";
export const submit_form_error = "An error occurred while submitting the form";

export const validation_error_message = {
  firstName: short_value_message,
  lastName: short_value_message,
  email: {
    type: "Please provide a valid email",
    value: short_value_message,
  },
  phoneNumber: "Phone number must be 10 digits long",
  password: "Password must be at least 7 characters long",
};
