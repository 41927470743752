import React, { PureComponent } from 'react';
import { FooterComponent } from './footer.component';

export class FooterContainer extends PureComponent {
  render() {
    return (<FooterComponent />
    );
  }
}

